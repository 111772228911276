html,
body {
  margin: 0;
}

.container {
  margin: 0;
  padding: 0;
}

a,
.btn-link {
  &,
  &:hover {
    color: #65b2b8;
    text-decoration: none !important;
  }
}

$header-height: 3rem;
$header-text-size: 1.7rem;
$header-text-margin: ($header-height - $header-text-size) / 2;
$search-input-size: 2rem;
$search-input-margin: ($header-height - $search-input-size) / 2;

#main-header {
  position: relative;
  height: $header-height;
  z-index: 2;

  vertical-align: middle;

  h1,
  nav {
    vertical-align: middle;
    display: inline-block;
    height: 100%;
  }

  h1 {
    // font-family: 'Nanum Brush Script', cursive;
    font-family: 'Unica One', cursive;
    font-size: $header-text-size;
    font-weight: lighter;
    margin: $header-text-margin 2em $header-text-margin 1em;
    height: $header-text-size;
  }

  // search
  nav {
    input {
      display: block;
      height: $search-input-size;
      margin: $search-input-margin 0;
      padding: 0 0.5em;
      border: none;
      vertical-align: middle;
      box-shadow: inset 0 0 1px grey;
    }

    ul {
      background-color: white;
      list-style-type: none;
      border: 1px solid lightgrey;
      padding: 0;

      li {
        margin: 0;

        a {
          display: block;
          padding: 0.25em 1em;
          text-decoration: none;
          color: inherit;

          &:hover {
            background-color: lightgray;
          }
        }
      }
    }
  }
}

#main-graph {
  padding-top: $header-height;
  margin-top: -$header-height;
  width: 100%;
  height: 100vh;
  position: relative;
}

#right-info-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  @media (min-width: 500px) {
    min-width: 380px;
    width: 25vw;
  }
  @media (max-width: 499.999px) {
    width: 100%;
  }

  #show-info,
  #hide-info {
    float: right;
    transform: rotate(90deg);
    font-size: 20px;
    color: black;
    text-decoration: none;
    z-index: 8000;
    position: fixed;
    right: 0;
  }

  #show-info {
    margin-top: 50px;
    margin-right: -40px;
  }

  #hide-info {
    margin-top: 45px;
    margin-right: -32px;
    font-weight: 400;
  }
}
